<script setup lang="ts">
defineOptions({
  name: 'AppVue'
})

const route = useRoute()
const session = useSupabaseSession()
const supabase = useSupabaseClient()
const { state } = useUserState(
  () => route,
  () => session,
  () => supabase
)
</script>

<template>
  <NuxtLayout>
    <!-- <div class="fixed z-10 bottom-1 right-5 bg-gray-800 text-white text-xs rounded-md">
      <pre>
        {{ state }}
      </pre>
    </div> -->
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss" scoped></style>
