import { default as indexusTBK4MymjMeta } from "/usr/src/ejaad-fullstack/pages/asset-preview_s_[assetID]/index.vue?macro=true";
import { default as indexDl6AayPWfRMeta } from "/usr/src/ejaad-fullstack/pages/employees/index.vue?macro=true";
import { default as connectG4xSJDK7cOMeta } from "/usr/src/ejaad-fullstack/pages/google/connect.vue?macro=true";
import { default as files_45selectorHToFPoUkUJMeta } from "/usr/src/ejaad-fullstack/pages/google/files-selector.vue?macro=true";
import { default as index6KOBrQVuETMeta } from "/usr/src/ejaad-fullstack/pages/index.vue?macro=true";
import { default as integrationsxF8pplc9G5Meta } from "/usr/src/ejaad-fullstack/pages/integrations.vue?macro=true";
import { default as indexXeSx17xf6xMeta } from "/usr/src/ejaad-fullstack/pages/login/confirm/index.vue?macro=true";
import { default as index5CH1XLcpIKMeta } from "/usr/src/ejaad-fullstack/pages/login/index.vue?macro=true";
import { default as indexRjQTLrP1bIMeta } from "/usr/src/ejaad-fullstack/pages/overview/index.vue?macro=true";
export default [
  {
    name: "asset-preview_s_assetID",
    path: "/asset-preview_s_:assetID()",
    component: () => import("/usr/src/ejaad-fullstack/pages/asset-preview_s_[assetID]/index.vue").then(m => m.default || m)
  },
  {
    name: "employees",
    path: "/employees",
    meta: indexDl6AayPWfRMeta || {},
    component: () => import("/usr/src/ejaad-fullstack/pages/employees/index.vue").then(m => m.default || m)
  },
  {
    name: "google-connect",
    path: "/google/connect",
    component: () => import("/usr/src/ejaad-fullstack/pages/google/connect.vue").then(m => m.default || m)
  },
  {
    name: "google-files-selector",
    path: "/google/files-selector",
    component: () => import("/usr/src/ejaad-fullstack/pages/google/files-selector.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/ejaad-fullstack/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "integrations",
    path: "/integrations",
    meta: integrationsxF8pplc9G5Meta || {},
    component: () => import("/usr/src/ejaad-fullstack/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: "login-confirm",
    path: "/login/confirm",
    meta: indexXeSx17xf6xMeta || {},
    component: () => import("/usr/src/ejaad-fullstack/pages/login/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: index5CH1XLcpIKMeta || {},
    component: () => import("/usr/src/ejaad-fullstack/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "overview",
    path: "/overview",
    component: () => import("/usr/src/ejaad-fullstack/pages/overview/index.vue").then(m => m.default || m)
  }
]